import axios from 'axios'
import Globals from './Globals'

export function getContentPath(path, fromCore){
    if(!path) {console.warn("empty path sent"); return "" }
    if( path.indexOf('http')===0)return path
    
    

    if(!fromCore && (Globals.instance().electron || Globals.instance().ios)){
        
        let downloadPath;
        
        // if(Globals.instance().nid && (downloadedArray.findIndex(a =>parseInt(a.id) === Globals.instance().nid) > -1)) {
            if (Globals.instance().nid && Globals.instance().useLocal) {
            
        
            if (Globals.instance().electron) {
                downloadPath = `${Globals.instance().appPath}/${Globals.instance().nid}/${path}`;
            }

            if (Globals.instance().ios) {
                downloadPath = `${Globals.instance().nid}/${path}`;
            }
            // console.log('getContentPath', downloadPath, Globals.instance().appPath)

            return downloadPath
        } 

        if (Globals.instance().ios && Globals.instance().controllerApp && Globals.instance().nid) {
            downloadPath = `${Globals.instance().nid}/${path}`;
            return downloadPath;
        }
        
        
    } 

   
    // console.log('getContentPath result', Globals.instance().contentpath + path)

    return Globals.instance().contentpath + path;

}


export function getCMSPath(path, fromCore){

    // console.log('getCMSPath', Globals.instance().useLocal, path, fromCore)

    if(((Globals.instance().electron || Globals.instance().ios) && Globals.instance().useLocal) || (Globals.instance().ios && Globals.instance().controllerApp)){
        let tokenParams;
        let p=path;
        if( path.indexOf('http')===0){
            let i=path.indexOf('/api/')
            p=path.slice(i+5) 
        }    
        if(p.slice(-12)==="?format=json")p=p.slice(0,-12)
        if(p.slice(-1)==='/') p=p.slice(0,-1)
        if(p.slice(-5)!=='.json')p=p+'.json'
        
        p = p.replace('-opt','')
        let downloadPath;
        let downloadedArray = [];
        let downloadCookie = localStorage.getItem('expo.downloaded')
        if (downloadCookie) {
            downloadedArray = JSON.parse(downloadCookie)
        }
        // console.log('getCMSPath', downloadedArray)

        // console.log("downloadedArray", downloadedArray, parseInt(Globals.instance().nid), Globals.instance().controllerApp)

        if (Globals.instance().electron && Globals.instance().useLocal) {
            downloadPath = `${Globals.instance().appPath}/${Globals.instance().nid}/${p}`;
        } else {
            downloadPath = `${Globals.instance().nid}/${p}`;
        }

        // TESTING
        if (!Globals.instance().nid) {
            downloadPath = `./api/${p}`;
            console.log("DOWNLOAD PATH CONTROLLER", downloadPath)
        }

        if (Globals.instance().ios && Globals.instance().controllerApp && Globals.instance().nid) {
            downloadPath = `${Globals.instance().nid}/${p}`;
            console.log("DOWNLOAD PATH Controller with NID", downloadPath, Globals.instance().nid, p)
        }

        
        
        
        // console.log("return nid version")
        console.log("returning cms path 0", downloadPath, Globals.instance().appPath, Globals.instance().nid)
        
        return downloadPath

    }

    let apiPath = Globals.instance().cmspath;
    // if (Globals.instance().controllerApp) {
    //     apiPath = this.adminAPI + "/api/"
    // }


    // Not sure where this is getting dropped so added this workaround for now.
    if (!Globals.instance().cmspath.includes('/api/')) {
        apiPath = Globals.instance().cmspath + "/api/"
    }
            
    if( path.indexOf('http')===0)return path //hard coded path goes there no matter what



    // console.log(Globals.instance().cmspath +path)
    console.log("returning cms path 1", apiPath + path)
    
    return apiPath + path;
}

export async function fetchCMSJSONs(arr,cancelToken){
    return new Promise((resolve,reject)=>{
    let arrPromises = []
        arr.forEach(val=>{ arrPromises.push(fetchCMSJSON(val,cancelToken))})
        Promise.all(arrPromises).then(values=>{  resolve(values) }).catch( error =>{
            reject(error)
        })
    })
}
export async function fetchCMSJSON(path,cancelToken,fromCore,tempToken){
    let token;
    let headers;

    console.log('fetchCMSJSON', getCMSPath(path,fromCore))
    
    let userObj;
    let strUser = localStorage.getItem('user')
    
    if (strUser) {
        userObj = JSON.parse(strUser)
        token = userObj['token']
    }

    if (token) {
        headers = {
            Authorization: 'Token ' + token
          }
    } else {
        headers = {}
    }

    
    return new Promise( (resolve,reject) => {
        axios({
            method: "get",
            url: getCMSPath(path, fromCore),
            headers: headers,
            }).then(response=>{
            // console.log('getCMSPath res', response.data, getCMSPath(path,fromCore))

            // TEMP WORKAROUND: NEED THIS FOR RECEIVE MODE
            if (!userObj) {
                console.log('!userObj')
                setTimeout(() => {
                    resolve(response.data)      
                }, 500);
            } else {
                resolve(response.data) 
            }
            
            
    
        })
        .catch(error=>{
            console.warn('could not fetch json', getCMSPath(path,fromCore))
            reject({error,path})
        })
    })
}

export async function loadContentFile(src){ 
    return new Promise( (resolve,reject) => {
        axios.get(getContentPath(src)).then(response=>{
            resolve(response.data)
        })
        .catch(error=>{
            console.warn('loadContentFile: could not fetch json',src)
            reject({error,src})
        })
    })
}

export async function loadImage(src){ return new Promise( resolve=>{
    let img = new Image() 
    img.src=src
    img.onload=()=>{resolve(img)}
    img.onError=()=>{console.error('Error loading image '+src)}
})}

export async function fetchThemes(cancelToken){ return fetchCMSJSON('core/theme'+(Globals.instance().usecms?'/':'.json'),cancelToken)}
export async function fetchNarratives(){ return fetchCMSJSON('core/narratives'+(Globals.instance().usecms?'/':'.json'))}
// export async function fetchNarrativeGroups(){ return fetchCMSJSON('core/narrative-group'+(Globals.instance().usecms?'/':'.json'))}

export async function fetchNarrativeGroups(){ return [] }

export function onErrorLoad(err){
    //save this somehow?
    // console.error(err)
    // window.dispatchEvent(new CustomEvent('alert',{details:err.toString}))
    
}