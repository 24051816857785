
import React,{useState,useRef,memo,useCallback,useEffect} from 'react'
import {getContentPath} from  'system/AssetManager'
import {animateParallax,getAnimationModel,animateIntersect,setInitialAnimIntersectState,setFinalAnimIntersectState} from './CanvasAnimator'
import Globals from 'system/Globals'
// import {onErrorLoad} from 'system/AssetManager'
import gsap, {Power3} from 'gsap'
import { animEventEmitter } from "system/utils/eventEmitter"; 

function PNGComponent(props){

    const [inline,setInline] = useState({})
    const [arInline,setArInline] = useState({})
    const [imgSrc,setImgSrc] = useState(null)
    const [pngOpacity, setPngOpacity] = useState(1)
     
    let bLoaded=false
    const loadingAnim = useRef();
    const refEle = useRef()
    const refArEle = useRef()
    const refArInnerEle = useRef();
    const refFormat = useRef()
    const refOrigOffset = useRef(0)
    const refAnimationModel = useRef({})
    const refRunAnim = useRef(false);
    
    
    useEffect(()=>{ 
        if (refEle.current) {
            refOrigOffset.current=refEle.current.offsetLeft
        }
        
        if(refFormat.current && refFormat.current.animation && refFormat.current.animation.typeName){
            
            refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
       

            if(props.renderOnly===true){
                setFinalAnimIntersectState(refEle.current,refFormat.current.animation)
            }
        }
        
    },[inline]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const onScroll = useCallback((scrollLeft,scale,pageWidth)=>{
        // console.log(refAnimationModel.current)
        animateParallax(refEle.current,refAnimationModel.current,scrollLeft,pageWidth)
    },[props.model.animation,refEle])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        // console.log('props.pageState', props.pageState)
        
        if (props.pageState === "animPage" && inline && refAnimationModel.current && refAnimationModel.current.animations) {
            console.log('props.pageState', props.pageState)

            animateIntersect(refEle.current,refAnimationModel.current)

        
        }
    }, [props, inline])


    const onIntersect = useCallback((evt,threshold)=>{
        // console.log('PNG onINTERSECT', evt, evt.detail.isIntersecting)
        if(evt.detail.isIntersecting )
        {
            if(evt.detail.intersectionRatio >=threshold)
         
                animateIntersect(refEle.current,refAnimationModel.current)
              

        }
        else if(evt.detail.intersectionRatio <=0.001)
            setInitialAnimIntersectState(refEle.current,refAnimationModel.current)
    },[refEle])

    const onAnim = useCallback((evt,threshold)=>{
        // console.log('PNG onAnim', evt)
        if(evt)
        {
            animateIntersect(refEle.current,refAnimationModel.current)

        }
        else {
            setInitialAnimIntersectState(refEle.current,refAnimationModel.current)
        }
            
    },[refEle])


    

    useEffect(()=>{



        refFormat.current = Globals.instance().getCanvasFormat(props.model.formats,props.format)
        
        // let style ={position:'absolute',transform: 'translateZ(0), perspective: 1000px',willChange:'transform'}
        let imgStyle ={position:'relative',transform: 'translateZ(0), perspective: 1000px', opacity: 0,  'width': '100%', 'height': '100%'};
        let arStyle = {position:'absolute',transform: 'translateZ(0), perspective: 1000px', opacity: 0};

        if (refFormat.current) {
            let resStyle;
            if(refFormat.current.style && !props.pageBkg){ 
                let left = refFormat.current.style.left;
                let top =  refFormat.current.style.top;
                let width = refFormat.current.style.width;
                let objectFit = refFormat.current.style.objectFit;
                
                let opacity = refFormat.current.style.opacity;
                let imgOpacity = refFormat.current.style.opacity;
                // if (refFormat.current.animation && refFormat.current.animation.typeName === "page") {
                //     let opacityAnim = refFormat.current.animation.animations.find(a => a.property === 'opacity');
                //     if (opacityAnim) {
                //         imgOpacity = opacityAnim['start']
                //     }
                //     console.log('hasOpacityAnim', hasOpacityAnimation(refFormat.current), opacityAnim)
                // }
                // console.log('PNG imgOpacity', imgOpacity, refEle.current)
                // console.log('refFormat.current', refFormat.current)
                
                // let width = parseInt(refFormat.current.style.width)/window.innerWidth*100 + "%";
                let height = refFormat.current.style.height;
                
                imgStyle = Object.assign(imgStyle,{'opacity': imgOpacity})
                setPngOpacity(imgOpacity)
                arStyle = Object.assign(arStyle,{'left': left, 'top': top, 'width': width, 'height': height, 'objectFit': objectFit, 'opacity': opacity})
                
                // console.log('newtop', width, refFormat.current.style, refEle.current.parentElement.offsetHeight)
                // style = Object.assign(style,refFormat.current.style) 
            } else {
                imgStyle = Object.assign(imgStyle,{'opacity': 1})
                arStyle = Object.assign(imgStyle,{'opacity': 1})
            }

      
            let f = refFormat.current.file?refFormat.current.file:refFormat.current.image
            // console.log("setting ",getContentPath(f))

            // console.log("FORMAT", refFormat.current, props.model.formats, props.format, f)

            setImgSrc(getContentPath(f))
            

   
            //set up animation Type
            if(refFormat.current.animation){

                if(refFormat.current.animation.typeName==='parallax'){
                    props.setScrollCallback(onScroll,props.index)
                    imgStyle.willChange='transform'
                } 
                else if(refFormat.current.animation.typeName==='page'){
                    props.onIntersect(onIntersect, props.index)
                    props.onAnim(onAnim, props.index)
                    imgStyle.willChange='transform, scale, opacity'
                }
            }
           
        }
        // console.log('imgStyle', imgStyle, refFormat.current)
        setInline(imgStyle)
        setArInline(arStyle);
        // animEventEmitter.on("valueChanged", handleValueChange);
       
        
        return ()=>{
            // animEventEmitter.off("valueChanged", handleValueChange);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    // useEffect(() => {
    //     if (imgSrc) {
    //         if (refEle.current && !bLoaded) {
    //             refEle.current.src = `${refEle.current.src}?t=${Date.now()}`;
    //             }
    //     }
    // }, [imgSrc])

    function onError(evt){
        console.error("PNG load error"+bLoaded, evt.target.src)
        // onErrorLoad(evt)
        // console.log("src "+evt.target.src)

        const img = refEle.current;
        let retryCount = 0;
        const maxRetries = 3;
        if (retryCount < maxRetries) {
            retryCount++;
            console.log(`Retrying image load (attempt ${retryCount})...`);
            setTimeout(() => {
              img.src = img.src; // Reload the image
            }, 1000); // Wait 1 second before retrying
          } else {
            console.error('Failed to load image after multiple retries.');
            // Optionally, display a placeholder image or error message
          }


        if(!bLoaded && imgSrc!==""){
            Globals.instance().trackError('PNG Component '+imgSrc+' ',false)

          

              
            onLoad() //dont hold up the page 
        }
    }
    
    function onLoad(){
        // console.log('onLoad', bLoaded, refFormat.current)
        // loadingAnim.current.pause();
        //down res the image
        if(!bLoaded){

            console.log('PNG loaded', refFormat.current.id)

            // gsap.to(refArEle.current, {
            //     backgroundColor: 'transparent',
            //     duration: 0.2,
            //     ease: "power1.inOut"
            // });
            // let opacity = refFormat.current.style.opacity;
            // gsap.to(refEle.current, {opacity: pngOpacity, duration: 0.2, delay: 1});
            gsap.to(refArInnerEle.current, {opacity: 1, duration: 0.2})

            props.onLoad(props.index,refEle.current,props.model)
            bLoaded=true

            if(props.renderOnly===true){
                refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
                setFinalAnimIntersectState(refEle.current,refAnimationModel.current)
            }

         

        }
        
    }


    return ( 
        <span className="arBox" style={arInline} ref={refArEle} id={refFormat.current && "component_dim_file_arBox_" + refFormat.current.component} data-fileformat={refFormat.current && refFormat.current.file_format}>
            <span className="arBoxInner" ref={refArInnerEle}>
                {(imgSrc && refFormat.current) ? <img src={imgSrc} id={refFormat.current && "component_dim_file_" + refFormat.current.component} alt="" onLoad={onLoad} style={inline} ref={refEle} data-opacity={pngOpacity} className={'force3d canvasImg' + ((props.pageBkg)?" bkg":"")} onError={onError}></img> : <span />}
            </span>
        </span>    
        
            
    )

}

const PNG = memo(PNGComponent, (prev,next)=>{  return prev.imgSrc === next.imgSr })
export  {PNG}
