
    /******  Controller/Server Events  ******/ 
import Globals from "./Globals";

var ControllerServerComs= (function(){
    var instance;
    class controllercoms {

      constructor(){ }
    
      // send command from the controller app to be received from server app
      sendUDPFromControllerToServer(event,command){
        if(window.webkit){
            let  udpcommand = typeof command === 'string' ? command : JSON.stringify(command)
            window.webkit.messageHandlers.controllerHandler.postMessage(JSON.stringify({event:'UDP',command: event+'|'+udpcommand}))
          }
          else console.warn("controller mode should be used on iPad")
          return;

          // let  udpcommand = typeof command === 'string' ? command : JSON.stringify(command)
          // console.log('SENDING COMMAND',JSON.stringify({event:'UDP',command: event+'|'+udpcommand}))
      }
      
      // post message from the controller app  to be received by ios controller app
      postMessageFromControllerToIOS(event,msg){
        if ((window.webkit)&&(Globals.instance().controllerApp)) {
           let  message = typeof msg === 'string' ? msg : JSON.stringify(msg)
           console.log('posting',JSON.stringify({event,message}))
           window.webkit.messageHandlers.controllerHandler.postMessage(JSON.stringify({"event":event,"message":message}))
        }
          else console.warn("This button should be used on iPad")
      }
        
      //post message from the server app to be received by bps electron app
      postMessageFromServerToElectron(event,msg){
          if (Globals.instance().serverApp) {
            let  message = typeof msg === 'string' ? msg : JSON.stringify(msg)
            console.log('to electron',{"event":event,"message":message})
            window.api.send("toApp", {"event":event,"message":message});
          }
      }
  
    }
    
  return {
    instance: function(){
      // check if instance is available
      if (!instance) {
        instance = new controllercoms();
        delete instance.constructor; // or set it to null
      }
      return instance;
    }
  };
})();


export default ControllerServerComs

