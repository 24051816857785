import React,{useRef,forwardRef,useCallback,useState,useEffect} from 'react'
import styles from './narrative.module.scss'
import {getContentPath} from 'system/AssetManager'
import gsap,{Power3} from 'gsap'

import {ReactComponent as IconArrowLeft} from 'assets/icons/arrow_left.svg'
import {ReactComponent as Burger} from 'assets/icons/burger.svg'
import {ReactComponent as CaretLeft} from 'assets/icons/caret_left.svg'
import {ReactComponent as Home} from 'assets/icons/expo-home-icon.svg'
import {ReactComponent as Apps} from 'assets/icons/apps.svg'
// import {useHistory} from 'react-router-dom'
import Globals from 'system/Globals'
import ControllerServerComs from 'system/ControllerServerComs';
import ToolsMenu from 'system/components/ToolsMenu'
import NarrativeMenuItem from './NarrativeMenuItem';
import getModuleIcon from 'system/components/ModuleIcons';

function isVideo(path){
    let ext = path.split('.').pop()
    if(ext.toLowerCase()==="mp4" || ext.toLowerCase()==="ogg" || ext.toLowerCase()==="mov" || ext.toLowerCase()==="mpeg" || ext.toLowerCase()==="mp4" || ext.toLowerCase()==="m4v" || ext.toLowerCase()==="mp4" || ext.toLowerCase()==="m4v"){
        return true
    }
    else return false
}

const NarrativeMenu =  forwardRef((props,ref)=>{
    

    const refRightCol = useRef()
    const refBtnContainerBkg = useRef()
    const refBtnContainer = useRef()

    const [first,setFirst]=useState(true)
    const [menuOpen,setMenuOpen] = useState(true)
    const refOnClose =useRef()
    const [isController, setIsController] = useState(false);
    const [navPos, setNavPos] = useState(null);
    const [landing, setLanding] = useState(false);
    // const [activePage, setActivePage] = useState();
    const [showModules, setShowModules] = useState(false);
    // const [menuItems,setMenuItems] = useState([])

    useEffect(()=>{
        
        if (Globals.instance().controllerApp) {
            setIsController(true);
        }

        refOnClose.current=()=>{
            closeNav()
        }
        const current = ref.current
        ref.current.addEventListener('close-menu',refOnClose.current)

        let params = new URLSearchParams(props.history.location.search)
        if(params.get('nav')==='closed'){
            closeNav()
        }
        
        const fanimIn=()=>{
            // console.log('animate in ',refRightCol.current)
            let rightStyle = window.getComputedStyle(refRightCol.current)
            let w= rightStyle.width
            let p = rightStyle.paddingRight
            refRightCol.current.style['min-width']=0
            refRightCol.current.style.width=0
            current.removeEventListener('animateIn',fanimIn)
            gsap.to(refRightCol.current,{duration:1.5,ease:Power3.easeInOut,width:w,paddingRight:p})
        }
        current.addEventListener('animateIn',fanimIn)
        
       
        return ()=>{
            current.removeEventListener('animateIn',fanimIn)
            current.removeEventListener('close-menu',refOnClose.current) 
        }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        if (props) {
            let elems1 = document.querySelectorAll('.stop1')
            elems1.forEach(element => {
              if (props.model.theme_css && props.model.theme_css['module-stop-0']) {
                element.setAttribute('stop-color', props.model.theme_css['module-stop-0'])
              }
            });
            let elems2 = document.querySelectorAll('.stop2')
            elems2.forEach(element => {
              if (props.model.theme_css && props.model.theme_css['module-stop-1']) {
                element.setAttribute('stop-color', props.model.theme_css['module-stop-1'])
              }
            });

 
   

            // buttonContainerBkg.current.style = style={{ background: 'linear-gradient(0deg, rgba(var(--primary-background),1) 0%, rgba(var(--primary-background),1) 30%, rgba(var(--primary-background),0) 100%)'}}
            
            // console.log('props.model', props.model.theme_css)
    
        }
    }, [props])

    useEffect(() => {
        if (props.user && props.user.link_token) {
            setLanding(true)
        } else {
            setLanding(false)
        }
       
    }, [props.user])

    useEffect(() => {
        if (!landing && props.theme) {
            function hexToRgb(hex) {
                var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                return result ? parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) : null;
              }
              
    
            if (props.theme && props.theme.css && props.theme.css['primary-background']) {
                refBtnContainerBkg.current.style.background = "linear-gradient(0deg, rgba(" + hexToRgb(props.theme.css['primary-background']) + ", 1) 0%, rgba(" + hexToRgb(props.theme.css['primary-background']) + ", 1) 80%, rgba(" + hexToRgb(props.theme.css['primary-background']) + ", 0) 100%)";
                // console.log('bkg update',hexToRgb(props.theme.css['primary-background']))
            }
            
        }
    }, [landing, props.theme])
    // useEffect(() => {
    //     if (props.socket && props.socketPrefs && props.socketPrefs.mode === "isReceive") {
    //         props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'narrative-menu-open?open=' + menuOpen]);
    //         console.log('page handsake sent', 'narrative-menu-open')
    //     }
    // }, [menuOpen, props.socket, props.socketPrefs])

  
    const closeNav= useCallback(()=>{
  
        // pauseVideo()
        if(ref.current && ref.current.querySelector('video')) ref.current.querySelector('video').pause()
        
        let params = new URLSearchParams(props.history.location.search)
        params.set('nav','closed')
        props.history.push({pathname:props.history.location.pathname,search:params.toString()})
        let eleColRight = ref.current.querySelector('.'+styles.menuColRight)
        
        // let vEle = ref.current.querySelector('video')
        // if(vEle) vEle.pause()
        if(!ref.current){
            Globals.instance().showAlert('error','could not close nav')
        }
        gsap.to(ref.current,{duration:1,left:'-100%',ease:Power3.easeInOut,
        
        onComplete:()=>{
            if(first && !Globals.instance().controllerApp){ 
                props.onFirstClose()
                setFirst(false)
            }
            props.setOnLanding(false);
            setTimeout(() => {
                setLanding(false);
            }, 300);
           
        }})
        

        gsap.to(refBtnContainer.current.querySelector('.btnBookmark'),{duration:1,opacity:0})
        // gsap.to(refBtnContainer.current,{left:posLeft,duration:1,ease:Power3.easeInOut})
        setMenuOpen(false)
        ref.current.setAttribute("overlap",'')
        
        if(first && Globals.instance().controllerApp){ 
            props.onFirstClose()
            setFirst(false)
        }
        
        let eleBG = ref.current.querySelector('.bg')
        if(eleBG && Globals.instance().presentationMode){
           gsap.to(ref.current,{duration:1,css:{backgroundColor:'transparent',ease:Power3.easeInOut}})
           gsap.to (eleColRight,{duration:1,borderRadius:0,ease:Power3.easeInOut})
           gsap.to(eleBG,{duration:1,css:{marginRight:eleColRight.clientWidth+'px',ease:Power3.easeInOut}})
        }

        if (!first && props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
            props.socket.emit('cast', [props.socketPrefs.room, 'narrative-menu-open?open=false']);
            console.log('close submitted')
        }
        if (!first && props.socketPrefs && props.socketPrefs.mode === "isReceive") {
            props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'narrative-menu-open?open=' + props.openMenu]);
            console.log('page handsake sent', 'narrative-menu-open?open=' + props.openMenu)
        }

       
        
       
        // gsap.to(refTools.current,{duration:0.5,opacity:0,ease:Power3.easeInOut});
    },[first, props, ref])

    const openNav= (full) => {


        let params = new URLSearchParams(props.history.location.search)
        params.set('nav','open')
        props.history.push({pathname:props.history.location.pathname,search:params.toString()})

        let eleColRight = ref.current.querySelector('.'+styles.menuColRight)
        let pos=full?0:-eleColRight.offsetLeft
        gsap.to(ref.current,{duration:1,left:pos+'px',ease:Power3.easeInOut})

        let eleBG = ref.current.querySelector('.bg')
           
        setMenuOpen(true)
        if(full){
        

            
            ref.current.removeAttribute("overlap")
            gsap.to(refBtnContainer.current,{left:0,duration:1,ease:Power3.easeInOut})
            playVideo()
            
            if(eleBG && Globals.instance().presentationMode){
                let color = ref.current.style.getPropertyValue('--primary-background'); 
                gsap.to(ref.current,{duration:1,css:{backgroundColor:color,ease:Power3.easeInOut}})
                gsap.to (eleColRight,{duration:1,css:{borderRadius:'75px 0 0 0'},ease:Power3.easeInOut})
                gsap.to(eleBG,{duration:1,css:{marginRight:0,ease:Power3.easeInOut}})
            }
        }else{
          
            ref.current.setAttribute("overlap",'')
            gsap.to(refBtnContainer.current,{left:0,duration:1,ease:Power3.easeInOut})

            
            if(eleBG && Globals.instance().presentationMode){
                gsap.to(ref.current,{duration:1,css:{backgroundColor:'transparent',ease:Power3.easeInOut}})
                gsap.to (eleColRight,{duration:1,borderRadius:0,ease:Power3.easeInOut})
                gsap.to(eleBG,{duration:1,css:{marginRight:eleColRight.clientWidth+'px',ease:Power3.easeInOut}})
            }

           
        }

        
        setNavPos(pos);
        if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
            props.socket.emit('cast', [props.socketPrefs.room, 'narrative-menu-open?open=true']);
        }
      
    }

   

    const onDown= useCallback((evt)=>{
        setTimeout(() => {
            setLanding(false);
        }, 300);

        let startPosX = (evt.touches && evt.touches.length)?evt.touches[0].clientX:evt.clientX
        let diffX = 0

        const maxW=ref.current.getBoundingClientRect().width
   
        let bDragging = false
        let colorFrom = getComputedStyle(ref.current).getPropertyValue('--primary-background'); 
                
      
        let dis = 0
        let eleBG = ref.current.querySelector('.bg')
        // let eleMenuRight = ref.current.querySelector('.menu-right')

        const onMove=(evt)=>{
            
            let posX =  (evt.touches && evt.touches.length)?evt.touches[0].clientX:evt.clientX;
            diffX= posX-startPosX;
            startPosX=posX
            let destPosX = (ref.current.offsetLeft +diffX)
            destPosX=destPosX>0 ? 0 :destPosX
            destPosX=destPosX < -maxW?  -maxW:destPosX
            let percent = -destPosX/maxW

            ref.current.style.left =  destPosX+'px'
            let leftEdge=window.innerWidth - refBtnContainer.current.clientWidth
            refBtnContainer.current.style.left= (Math.min(-(ref.current.offsetLeft/window.innerWidth) * leftEdge,leftEdge))+'px'
            
            dis=dis + Math.abs(diffX)
            
            if(dis>10)
            bDragging=true

            if(Globals.instance().presentationMode){
                // console.log(Math.round(percent*75.0)+'px 0 0 0')
                let pW= Math.abs(percent * (1/(1-(refRightCol.current.clientWidth/maxW))))
                gsap.set(ref.current,{backgroundColor:gsap.utils.interpolate(colorFrom,'transparent',pW)})
                refRightCol.current.style.borderRadius=Math.round((1-pW)*75.0)+'px 0 0 0'
                eleBG.style.marginRight=(pW * parseFloat(refRightCol.current.clientWidth))+'px'
            }


  
            
        }
        const onUp=(evt)=>{
            evt.preventDefault(); 
            //check if we need open or close
            if(bDragging && startPosX/window.innerWidth > 0.35 ){
                openNav(true)
            }
            else if(!bDragging){
                if(menuOpen)
                    closeNav()
                else
                    openNav(false)
            }
            else {
                // let percent = (refFirst.current)?.5:0.1
                if(menuOpen)
                    closeNav()
                else
                    openNav()
            }
            window.removeEventListener('mousemove',onMove)
            window.removeEventListener('mouseup',onUp)
            window.removeEventListener('touchmove',onMove)
            window.removeEventListener('touchend',onUp)
            window.removeEventListener('touchcancel',onUp)
            window.removeEventListener('mouseleave',onUp)
        }

        window.addEventListener('mousemove',onMove,{passive:true})
        window.addEventListener('touchmove',onMove,{passive:true})
        window.addEventListener('touchend',onUp,{passive:false})
        window.addEventListener('touchcancel',onUp,{passive:false})
        window.addEventListener('mouseup',onUp,{passive:false})
        window.addEventListener('mouseleave',onUp,{passive:true})
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ref,closeNav,openNav])


    useEffect(() => {
        
            // console.log('receive', props.openMenu)
            if (props.openMenu !== "init") {
                if (props.openMenu) {
                    openNav()  
                    // console.log('props.openMenu', props.openMenu)

                    if (props.socketPrefs && props.socketPrefs.mode && props.socketPrefs.mode === "isReceive") {
                        props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'narrative-menu-open?open=' + props.openMenu]);
                        // console.log('page handsake sent', 'narrative-menu-open?open=' +props.openMenu)
                    }
                            
                } else {

                   
                    closeNav()


                    // console.log('props.openMenu', props.openMenu)

                }
            }
            if (props.openMenu === "init") {
                openNav('full')
            }
          
           
        
       
    }, [props.socketPrefs, props.openMenu])

    

    
    function playVideo(){
        if(ref.current && ref.current.querySelector('video')) ref.current.querySelector('video').play()
    }
    function onClickBack(){
        // pauseVideo()


        if (navPos < 0) {
            openNav('full') 
            setLanding(true);

            if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
               
                props.socket.emit('cast', [props.socketPrefs.room, 'narrative-menu-open?open=full']);
            
            }
           
        } else { 
            Globals.instance().useLocal = false;

            Globals.instance().contentpath = Globals.instance().loadedContentpath
            
            if(Globals.instance().ios){
                //dispatch close narrative webview
                window.webkit.messageHandlers.notification.postMessage({ Object: 'close-narrative' })
                return
            }
            else if(Globals.instance().homeLink){
                
                if(window.location.pathname.indexOf("/home")!==-1)return
                props.history.push(Globals.instance().homeLink)

                if(Globals.instance.controllerApp){
                    ControllerServerComs.instance().sendUDPFromControllerToServer('navigate',Globals.instance().homeLink)   
                }

            }else{

                if (!props.socketPrefs || (props.socketPrefs && !props.socketPrefs.meeting)) {
                    Globals.instance().nid=null;
                   
                    props.history.push(Globals.instance().getRoute('/home'))
                    
                    // Need to show alert and handing meeting end if go to home?
                }

                if(Globals.instance.controllerApp){
                    ControllerServerComs.instance().sendUDPFromControllerToServer('navigate','/home')   
                }
            }

            if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
               
                props.socket.emit('cast', [props.socketPrefs.room, 'nav#path=/home']);
            
            }
          
        }

       
    }

   function onSetDrawControls(val){
       closeNav()
       props.setShowDrawControls(val)
   }

  
    
    const onClickLink = useCallback((menuLinkIndex) => {

        // console.log('NarrativeMenu: onClickLink', menuLinkIndex, props.model.menu_links)

        setShowModules(false);

        closeNav()
        let index;

        if (menuLinkIndex) {
            index = menuLinkIndex
        } else {
            index = 0;
        }

        const page = props.model.menu_links[index].page;

        // console.log('onClickLink page', page)
        
        props.onScrollEvent(page, 'fade', 'NarrativeMenu: onClickLink')
        Globals.instance().trackEvent('click-link',{ 'event_category':'tools'})
        
        if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
            props.socket.emit('cast', [props.socketPrefs.room, 'narrative-menu-link?page=' + page + '&origin=/narrative/' + props.model.id]);
        }
        // let pageIndex = props.flatMenuItems.findIndex(o => o.page === page)
        // props.setCurrentPage(pageIndex)

        // console.log("onClickLink: pageId, pageIndex", page)
        gsap.to(props.menuEle, {opacity: 1})

    },[closeNav, isController, props])
    
    useEffect(() => {
        // console.log('navPos', navPos);

       
        if (navPos === 0) {
            props.setOpenMenuFull(true);
        } else {
            if (navPos < 0) {

                props.setOpenMenuFull(false);
            }
        }

    }, [navPos])

    useEffect(() => {
        

        if (props.openMenuFull) {
            // console.log('props.openMenuFull', props.openMenuFull);
            
            openNav('full');
        }
        
    }, [props.openMenuFull])

    // useEffect(() => {
    //     console.log("props.activePage, props.PageModels", props.activePage, props.PageModels)
    //     if (props.activePage && props.PageModels) {
    //         let activePageObj = props.PageModels.find(o => o.id === props.activePage);
    //         setActivePage(activePageObj)
    //         console.log("activePageObj", activePageObj.id)
    //     }
    // }, [props.activePage, props.PageModels])
    
    const onExpandSubs =useCallback(evt=>{
        // console.log(evt)
        let openEle = refRightCol.current.querySelector(`.${styles.menuLink}[open]:not([index="${evt}"])`)

        let openEle2 = refRightCol.current.querySelector(`.${styles.menuLink}[open][index="${evt}"]`)
  
        // console.log(openEle2)

        if (openEle2) {
            let scrollY = window.scrollY - openEle2.getBoundingClientRect().top 
            gsap.to('#menuLinksContainer', { duration: 0.25, scrollTo: { y: scrollY }, ease: "linear" });
            // console.log('scrollY', scrollY)
        }

        if(openEle){
            // openEle.scrollTo({top: 40, left: 0, behavior: "smooth"})
            openEle.dispatchEvent(new CustomEvent('close'))
        }
    },[])

    function onOpenLeftNav() {
		// setOpenMenu(true);
		// setOnLanding(false);
        if (menuOpen) {
            closeNav()
        } else {
            openNav()
        }   
        

	}
    
    function onClickMenuIcon(index) {
        if (index === -1) {
            if (landing) {
                onClickLink() 
            } else {
                setShowModules(false);
                closeNav();

            }
        } else {
            setShowModules(false)
            props.onClickModule(index)
        }
    }
    
    return (
            
            <div className={`${styles.menuContainer} force3d ${landing?styles.onLanding:styles.leftNav}`} open={menuOpen} ref={ref}>

            {
                props.model.menu_background_right &&
                ( <img src={getContentPath(props.model.menu_background_right)} alt="" className={`${styles.menuRightBg} bg`}></img>)
            }
            { !props.model.menu_background  && 
                (   <img src={getContentPath('core/narrative/loading-bg.webp',true)} className={`${styles.menuBg}  bg`} alt="" />)
            }
            { props.model.menu_background && !isVideo(props.model.menu_background) && 
                    (   <img src={getContentPath(props.model.menu_background)} className={`${styles.menuBg}  bg`} alt="" />)
            }
            {
             props.model.menu_background && isVideo(props.model.menu_background) && 
                    // (   <video muted loop playsInline autoPlay preload={1} src={getContentPath(props.model.menu_background)} className={`${styles.menuBg} constant bg`} alt="" />)
                    (<span />)
            }
            
            <div className={`${styles.menuColRight} rightcolcontainer ${props.menuItems && (props.menuItems.length >= 4) ? styles.menuGrid : styles.menuRow }`} ref={refRightCol} style={
                props.model.menu_background_right?{backgroundColor:'transparent'}:{}
            }>
            

                <span className={styles.menuInnerColumn}>

                    { props.menuItems && props.model.menu_logo ? (
                        <img src={getContentPath(props.model.menu_logo)} alt="logo" className={`${styles.logo} logo`}/>
                    ) : ((props.user && props.user.org && props.user.org.logo) ? (props.menuItems && <img src={getContentPath(props.user.org.logo)} alt="logo" className={`${styles.logo} logo`} />) : "")}

                    {!showModules && <div className={`${styles.menuTitle}`}>{props.model.subheading}</div>}
                   
                        <div className={`${styles.menuRightColContainer} menu-right hide-scroller`} >
                            {props.modules && props.icons &&
                                
                                <div className={`${styles.menuColPos} ${showModules?styles.menuColOn:""}`}><div className={`${styles.menuModuleContainer} ifServerHide`} style={Globals.instance().getThemeStyle(['nav-modules-container'],props.theme)}>
                          
                                {props.icons.map((icon, index) =>{
                                    return (
                                        <div className={`${styles.moduleLink}`} key={`nar-menu-mode-item-${index}`} index={index-1} 
                                        onClick={() => onClickMenuIcon(index-1)}>
                                            <span className={`${styles.moduleLinkIcon}`}> 
                                                {icon} 
                                        
                                            </span>
                                        </div>
                                )})}
                                
                                </div></div>
                        
                            }

                            <div className={`${styles.menuColPos} ${!showModules?styles.menuColOn:""}`}><div className={`${styles.menuLinksContainer} hide-scroller`} id="menuLinksContainer">
                            {!landing && <span className={styles.switchMenu} onClick={() => setShowModules(showModules => !showModules)}><span className={styles.switchMenuBtn}><Apps /></span><h4>Presentation</h4></span>}
                            {props.menuItems && props.activePage && props.menuItems.map((val,index)=>{
                                
                                    return(
                                    <NarrativeMenuItem
                                    model={val}
                                    index={index}
                                    key={`nar-menu-link-${index}-${props.activePage.id}`} 
                                    onClickLink={ onClickLink } 
                                    onExpand={onExpandSubs}
                                    className={styles.menuLink}
                                    theme={props.theme}
                                    style={Globals.instance().getThemeStyle(['nav-menu-item'],props.theme)}
                                    // activePageId={props.activePage.id}
                                    activePage={props.activePage}
                                    pageIndex={props.pageIndex}
                                    ></NarrativeMenuItem>
                                    )
                                })
                            }
                            </div></div>

                            
                        </div>

                    </span>
                    
                </div>
                            
                            
           
                <div className={`${styles.buttonContainer} ifServerHide `} ref={refBtnContainer}>
                {!landing && <span className={styles.buttonContainerBkg} ref={refBtnContainerBkg} />}
                    <div className={`${styles.buttonContainerMenu}`}>
                        {landing && <div className={`round-btn` + ((landing && props.link_token)?" tokenUser":"")} onClick={onClickBack} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                            <Home />
                        </div>}
        
        
                        <div className={`${styles.leftNavBurger}`} onClick={() => onOpenLeftNav()} ><div className={`round-btn`}>
                        <Burger /></div></div>
                    </div>
                    
                    <span className={styles.homeBtnCol}>
                        {!landing && <div className={`${styles.homeBtn}`} onClick={onClickBack} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                        <Home />
                        </div>}


                        <div className={`${styles.toolBtn}`}><ToolsMenu theme={props.theme} 
                        setShowDrawControls={onSetDrawControls} 
                        setShowControls={props.setShowControls} 
                        setShowMarkup={props.setShowMarkup}
                        model={props.model}
                        
                        ></ToolsMenu></div>
                       
                    </span>
                   
                </div>

               <div className={`${styles.menuBurger} ${menuOpen?styles.hide:""}`} onClick={() => onOpenLeftNav()} ><div className={`round-btn`}><Burger /></div></div>
        
                            
        </div>
           
    )
})

const NarrativeMenuMemo  = React.memo(NarrativeMenu)

export {NarrativeMenuMemo as default, getModuleIcon, isVideo}

