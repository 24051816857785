
import React,{useState,useRef,memo,useCallback,useEffect, Fragment} from 'react'
import {getContentPath} from  'system/AssetManager'
import {animateParallax,getAnimationModel,animateIntersect,setInitialAnimIntersectState,setFinalAnimIntersectState} from './CanvasAnimator'
import Globals from 'system/Globals'
import history from 'system/navigation/MainRouterHistory'
import {
    Link
} from "react-router-dom";
import gsap, {Power3} from 'gsap'

function PNGComponent(props){
    
    
    let bLoaded=false
    const [inline,setInline] = useState({})
    const [imgSrc,setImgSrc] = useState("")
    const refEle = useRef()
    const refEleOn = useRef()
    const refFormat = useRef()
    const refOrigOffset = useRef(0)
    const refAnimationModel = useRef({})
    // let history = useHistory();

    useEffect(()=>{ 
        refOrigOffset.current=refEle.current.offsetLeft
        if(refFormat.current && refFormat.current.animation){
            refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
            if(props.renderOnly===true){
                setFinalAnimIntersectState(refEle.current,refFormat.current.animation)
            }
        }
       
    },[inline]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const onScroll = useCallback((scrollLeft,scale,pageWidth)=>{
        animateParallax(refEle.current,refAnimationModel.current,scrollLeft,pageWidth)
    },[props.model.animation,refEle])// eslint-disable-line react-hooks/exhaustive-deps

    const onIntersect=  useCallback((evt)=>{
        if(evt.detail.isIntersecting )
        {
            if(evt.detail.intersectionRatio >=0.5)
                animateIntersect(refEle.current,refAnimationModel.current)  
               
        }
        else if(evt.detail.intersectionRatio <=0.001)
            setInitialAnimIntersectState(refEle.current,refAnimationModel.current)
    },[refEle])

    const onAnim = useCallback((evt,threshold)=>{
            console.log('PNG onAnim', evt)
            if(evt)
            {
                animateIntersect(refEle.current,refAnimationModel.current)
    
            }
            else {
                setInitialAnimIntersectState(refEle.current,refAnimationModel.current)
            }
                
        },[refEle])

    useEffect(()=>{

        // console.log("PNGButton props", props, refFormat.current)
        
        refFormat.current = Globals.instance().getCanvasFormat(props.model.formats,props.format)
          
        let style ={position:'absolute',transform: 'translateZ(0), perspective: 1000px',willChange:'transform','cursor':'pointer', 'opacity': 1}
        if(refFormat.current.style){ style = Object.assign(style,refFormat.current.style) }
        let opacity = refFormat.current.style.opacity;
        // console.log("opacity", opacity, refFormat.current.style)
        if (opacity) {
            style['opacity'] = opacity
        }
        
        setInline(style)

        let f =refFormat.current.file?refFormat.current.file:refFormat.current.image
        setImgSrc(getContentPath(f))
        
        if(refFormat.current.animation){
            if(refFormat.current.animation.typeName==='parallax'){
                props.setScrollCallback(onScroll,props.index)
            } 
            else if(refFormat.current.animation.typeName==='page'){
                props.onIntersect(onIntersect,props.index)
                props.onAnim(onAnim, props.index)
            }
        }
        return ()=>{}
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const goToNarrativeLink = (link, module_id) => {

        // console.log("goToNarrativeLink", link)

        let moduleId = link.split("module=");
        let narrativeId = link.split("narrative=");
        let slideId = link.split("slide=");
        if (narrativeId[1]) {
            let narrativeTarget = narrativeId[1];
            let narrativeParams = narrativeTarget.split('?');
            let narrativeIdTarget = narrativeParams[0];

            console.log('goToNarrativeLink', narrativeIdTarget)

            narrativeId && console.log("goToNarrativeLink", narrativeIdTarget, props, narrativeTarget);
            
            // props.setCurrentParams(null);
            // props.setCurrentModule(null);
            // props.setActivePage(null);
            // props.setActiveNarrativePageIndex(0);
            // props.setGoToNarrative(narrativeIdTarget);
          
            setTimeout(()=>{

                if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {

                    props.socket.emit('cast', [props.socketPrefs.room, 'nav#path=' + narrativeIdTarget]);
        
                    console.log('nav#path=', narrativeIdTarget)
                }

                // ControllerServerComs.instance().sendUDPFromControllerToServer('navigate', '/narrative/' + narrativeTarget )
         
            },300)
        } else if (moduleId[1]) {
            // console.log('moduleId[1]', moduleId[1])
     
            // let moduleIdTarget = moduleId[1];
            // if (moduleParam) {
            //     moduleIdTarget = moduleParam[0];
            // }
            // console.log('moduleId', moduleIdTarget, moduleParam)
            // moduleId && console.log("goToNarrativeLink", moduleIdTarget, props);
            // let moduleIndex = props.modules.findIndex(md=>{return md.id===moduleIdTarget})
            // let m = props.modules.find(md=>{return md.id===moduleIdTarget})
            // if(m.typeName!=="controller-narrative") props.setCurrentModule(m);
            // if(m.typeName!=="controller-narrative") props.setAnimInCurrentModule(true);
            // props.setCurrentParams(link);
            // props.setActivePage(null);

            console.log('set current params' + link)
            
            setTimeout(()=>{

                // props.socket.emit('cast', [props.socketPrefs.room, 'select-module-id?module=' + moduleId[1]]);

                if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {

                    props.socket.emit('cast', [props.socketPrefs.room, 'nav#path=' + link]);
                
                    props.socket.emit('cast', [props.socketPrefs.room, 'narrative-module-id#link=' + link]);
                    
                    console.log("broadcast button", 'narrative-module-id#path=' + link)
                    
        

                }

                // moduleId && console.log("cast", 'select-module-id?module=' + moduleId[1]);
                // ControllerServerComs.instance().sendUDPFromControllerToServer('navigate', '/narrative/' + props.model.id + link)
                // ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-module-cta', moduleIndex-1);
            },300)
            // console.log("goToNarrativeLink result", moduleIndex-1, m)
            
        } else if (slideId[1]) {
            

      
        } else {
            console.log('no link found')
        }
   
    }


    let onClick=useCallback(()=>{
        
        console.log("onClick PNGButton", props)
        
        if(props.model.content.module){
            
            let url = new URL(window.location.href)
            let urlparams= new URLSearchParams(props.model.content.module)
            let moduleid=urlparams.get("module")
            let slideId=urlparams.get("page")
            let target=urlparams.get("target")
            let toggle=urlparams.get("toggle")

            let locParms = new URLSearchParams(document.location.search);
            let userId=locParms.get("u")

            let pathLink = props.model.content.module;
            let isExternal = pathLink.includes('http') ||  pathLink.includes('https') ||  pathLink.includes('mailto');

            console.log('Globals.instance().useHashRouter', Globals.instance().useHashRouter, isExternal, pathLink, locParms)

            if((Globals.instance().useHashRouter)&&(!isExternal))
            {
                let path = url.hash.substring(1)
                let arr=path.split('?')
                let search=''
                if(path.indexOf('?')){
                    path=arr[0]
                    search=arr[1]
                    
                }
                 search=search?'&'+props.model.content.module:props.model.content.module +"&landing=false" 

                console.log("local png button", search + "&back=" + props.pageId, url.hash.substring(1)+'&'+props.model.content.module, props)
                
                history.push({pathname:path,search:'?'+search + "&back=" + props.pageId})

                if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
                    goToNarrativeLink(path + search + "&back=" + props.pageId)
                }

            }else{

               
                if (isExternal) {
                    console.log('isExternal')
                    // history.push({pathname:url.pathname, search:'?'+props.model.content.module})  
                    if(Globals.instance().electron) {
                        window.api.send("toApp", {"event":"external-link","link":props.model.content.module});
                        console.log('electron', props.model.content.module)
                    } else if(Globals.instance().ios) {
                        console.log('ios', props.model.content.module)
                        window.webkit.messageHandlers.notification.postMessage({ Object: 'external-link', 'link': props.model.content.module})
                        
                    } else {
                        window.open(props.model.content.module, "_blank");
                    }


                } else {
                    console.log('history push props', props.model.content.module, props, url.pathname)
                    console.log('pathLink', pathLink, "/narrative/" + props.nid)
                    let userToken;
                    if (userId) {
                        userToken = "u=" + userId + "&";
                    }
                    console.log('link result', slideId, props.flatMenuItems, target)

                    if (target) {
                        let targetId = "component_dim_file_" + target;
                        let targetId_arBox = "component_dim_file_arBox_" + target;
              
                        let targetElem = document.getElementById(targetId);
                        let targetElem_arBox = document.getElementById(targetId_arBox);
                        let opacity = 1;
     
                  
                        let opacityStart = targetElem.dataset.opacity;
                        let opacityCurr = targetElem.style.opacity;
                        let fileOn = refFormat.current.file_on;
                        let file = refFormat.current.file;

                        console.log('targetElem', targetElem, targetId)
                        console.log('opacity settings', opacityCurr, opacityStart)

                        if (opacityCurr !== opacityStart) {
                            gsap.to(targetElem, {duration:0.35,ease:Power3.easeInOut,opacity:opacityStart, pointerEvents: "auto"})
                            setImgSrc(getContentPath(file))     
                            gsap.to(targetElem_arBox, {duration:0.35,ease:Power3.easeInOut,opacity:opacityStart, pointerEvents: "auto"})      
                        } else {
                            gsap.to(targetElem, {duration:0.35, ease:Power3.easeInOut, opacity:opacity, pointerEvents: "auto"})
                            gsap.to(targetElem_arBox, {duration:0.35, ease:Power3.easeInOut, opacity:opacity, pointerEvents: "auto"})
                            if (fileOn){
                                setImgSrc(getContentPath(fileOn))
                            }
                        }
                        if (toggle && toggle === '1') {
                            gsap.to(refEle.current, {duration:0.35,ease:Power3.easeInOut,opacity:0})
                            gsap.to(refEle.current, {duration: 0, pointerEvents: "none"})
                        }

                       
                        
                        
                    }

                    if (slideId) {
                        let pageIndex = props.flatMenuItems.findIndex(o => o.page === parseInt(slideId) )
                        props.onScrollToEvent(parseInt(slideId), 'fade')
                        props.setCurrentPage(pageIndex)
                
                    }

                    if (module){

                        let search = userToken + props.model.content.module + "&back=" + props.pageId;
                        console.log('search', search)
                        if (url.pathname.indexOf('C:/') > -1) {
                            url.pathname = url.pathname.replace('C:/', '');
                        }
                        let pathname = url.pathname;
                        if (Globals.instance().ios && !Globals.instance().useLocal && props.nid) {
                            pathname = '/narrative/' + props.nid
                        }
                        history.push({pathname:pathname, search:'?'+ search})      
               
                        
                        if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
                            goToNarrativeLink(url.pathname + props.model.content.module, moduleid)
                        }

                    }
                    
                    // if (slideId) {
                    //     let pageIndex = props.flatMenuItems.findIndex(o => o.page === parseInt(slideId) )
                    //     props.onScrollToEvent(parseInt(slideId), 'fade')
                    //     props.setCurrentPage(pageIndex)
                
                    // } else {


                    //     let search = userToken + props.model.content.module + "&back=" + props.pageId;
                    //     console.log('search', search)
                    //     if (url.pathname.indexOf('C:/') > -1) {
                    //         url.pathname = url.pathname.replace('C:/', '');
                    //     }
                    //     let pathname = url.pathname;
                    //     if (Globals.instance().ios && !Globals.instance().useLocal && props.nid) {
                    //         pathname = '/narrative/' + props.nid
                    //     }
                    //     history.push({pathname:pathname, search:'?'+ search})      
               
                        
                    //     if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
                    //         goToNarrativeLink(url.pathname + props.model.content.module, moduleid)
                    //     }

                    // }
                   
                }
                
                
            }
            
            
            
            if(moduleid){

                console.log(moduleid)
                refEle.current.dispatchEvent(new CustomEvent('narrative-pop-up', { detail:{typeName:"module",module:moduleid},bubbles:true}))
            }



            return
        }
        // 
    },[refEle,props.model])
    
    function onError(evt){
        
        if(!bLoaded && imgSrc!==""){
            console.error("PNG BTN ERROR",evt)
            Globals.instance().trackError('PNG Button Component '+imgSrc+' ',false)
            onLoad() //dont hold up the page 
            if(props.renderOnly===true){
                refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
                setFinalAnimIntersectState(refEle.current, refAnimationModel.current)
            }

        }
    }
    

    function onLoad(){
        //down res the image
        if(!bLoaded){
            props.onLoad(props.index,refEle.current,props.model)
            bLoaded=true
        }
    }
    
    return ( 
        <img id={refFormat.current && "component_dim_file_" + refFormat.current.component} className={'force3d canvasImg' + (props.renderOnly === true ? " noAnim":"")} src={imgSrc} onError={onError} onClick={onClick} alt="" onLoad={onLoad} style={inline} ref={refEle} data-opacity={inline.opacity} data-fileformat={refFormat.current && refFormat.current.file_format}></img>
        
    )

}

const PNGButton = memo( PNGComponent ,(prev,next)=>{  return prev.imgSrc === next.imgSr })
export  {PNGButton}
// <Fragment>
// <img id={refFormat.current && "component_dim_file_on_" + refFormat.current.id} className={'force3d canvasImg' + (props.renderOnly === true ? " noAnim":"")} src={imgSrc} onError={onError} onClick={onClick} alt="" onLoad={onLoad} style={inline} ref={refEleOn}></img>
// <img id={refFormat.current && "component_dim_file_" + refFormat.current.id} className={'force3d canvasImg' + (props.renderOnly === true ? " noAnim":"")} src={imgSrc} onError={onError} onClick={onClick} alt="" onLoad={onLoad} style={inline} ref={refEle}></img>
// </Fragment>
