
import React,{Fragment, useEffect,useRef,useState} from 'react'
import {getContentPath} from  'system/AssetManager'
// import {fetchCMSJSON,fetchCMSJSONs} from 'system/AssetManager'
import gsap,{Power2} from 'gsap'
import classNames from 'classnames';
import Globals  from 'system/Globals';
import styles from './controller-narrative.module.css'
import nstyles from '../narrative/narrative.module.scss'
import mstyles from '../narrative/modules/modules.module.css'
import NarrativeMenuItem  from 'narrative/NarrativeMenuItem'
import ControllerServerComs from 'system/ControllerServerComs'
import ControllerJoystick from './ControllerJoystick'
import ControllerVideoUI from './ControllerVideoUI';
import {ReactComponent as Chevron} from '../assets/icons/caret_down.svg'
import {ReactComponent as CloseButton} from '../assets/icons/close.svg'
import {ReactComponent as ChevronThinButton} from '../assets/icons/caret_right.svg'
import {ReactComponent as PlayButton} from '../assets/icons/play.svg'
import {ReactComponent as VRButton} from '../assets/icons/vr_white.svg'
import {ReactComponent as Pin} from '../assets/icons/mapbox_pin.svg'
import {ReactComponent as ZoomOut} from '../assets/icons/minus.svg'
import {ReactComponent as ZoomIn} from '../assets/icons/plus.svg'
import {ReactComponent as CaretRight} from '../assets/icons/caret_right.svg'
import {ReactComponent as CaretLeft} from '../assets/icons/caret_left.svg'
import {ReactComponent as CaretUp} from '../assets/icons/caret_up.svg'
import {ReactComponent as CaretDown} from '../assets/icons/caret_down.svg'
import {ReactComponent as Bulb} from '../assets/icons/380S-bulb.svg'
import Help2Finger from '../assets/help/Directions-2fingers.webp'
import HelpSwipe from '../assets/help/Directions-swipe.webp'
import HelpTouch from '../assets/help/Directions-touch.webp'
import HelpHold from '../assets/help/Directions-hold.webp'

const ControllerGallery = (props)=>{

    const scrollContainer = useRef();
    const joyStickRef = useRef();
    const videoRef = useRef();
    const [menuOpen, setMenuOpen] = useState(true);
    const [selected,setSelected] = useState(null)
    const [selectedId, setSelectedId] = useState(null);
    const [styleMenu,setStyleMenu]=useState({})
    const [menuItems,setMenuItems] = useState([])
    const [styleMenuSelected,setStyleMenuSelected]=useState({})
    const [galleryItems, setGalleryItems] = useState(null);
    const [showJoystick, setShowJoystick] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [fileType, setFileType] = useState(null);
    const [isMatterport, setIsMatterport] = useState(false);
    const [activePage, setActivePage] = useState(null);
    const [vidIsPlaying, setVidIsPlaying] = useState(false);
    const [showLanding, setShowLanding] = useState(true);
    const [activeSerials, setActiveSerials] = useState([]);
    const [btnReady, setBtnReady] = useState(true)
    const [backPageIndex, setBackPageIndex] = useState(false)

    function processParams(){
        let params = new URLSearchParams(props.params);
        let file0 = params.get('file');
        let cat0 = params.get('category');
        let thumbs = params.get('thumbs');
        let landing = params.get('landing');
        let back = params.get('back');

        if (back) {
            setBackPageIndex(back)
        } else {
            setBackPageIndex(false)
        }


        if (cat0) {
            let catId = parseInt(cat0);
            let catIndex = menuItems.map(function(x) {return x.id; }).indexOf(catId);

            setSelected(catIndex);
            var results = [];
            props.module.files.forEach(obj =>{ 
                obj.categories.includes(menuItems[catIndex].id) && results.push(obj)
            });
            setGalleryItems(results);
            setShowLanding(false);
        
      
           
        }
        
        if (file0){
            let fileId = parseInt(file0);
            let fileIndex = galleryItems.map(function(x) {return x.id; }).indexOf(fileId);
            let catId;

            if (cat0) {
                catId = cat0
            }
            onThumbnailClick(galleryItems[fileIndex].id, galleryItems[fileIndex].fileType, "", "params", catId)
            
            let dest = document.getElementById('galleryThumb_' + fileId);
            dest.scrollIntoView()
        }   
        
       

        if ((thumbs === 'false')||(landing === 'false')) {
            setShowLanding(false);
        }
        
        console.log('processParams', file0, cat0);
        
        props.setCurrentParams(null);

    }
    
    useEffect(()=>{
     
        console.log('gallery props', props)
        
        return () => {
 
            // setSelected(0);
        }
    },[])

    useEffect(() => {
        if (showVideo) {
            props.setPopup(true)
        } else {
            props.setPopup(false)
        }
    }, [showVideo])

    useEffect(() => {
        if (showJoystick) {
            props.setPopup(true)
        } else {
            props.setPopup(false)
        }
    }, [showJoystick])

    useEffect(() => {
        if (props.reload > -1) {
            console.log('reload', props.reload)
            setShowLanding(true);
            setSelected(null);
        }
        
    }, [props.reload])

    useEffect(() => {
        if (props.params && menuItems && (menuItems.length > 0)) {
            processParams();
        }
    }, [props.params, menuItems])

    useEffect(()=>{
        if (props.module.id) {
            // setSelected(0);
            // setActivePage(props.activeNarrativePageIndex);
            // props.setActiveNarrativePageIndex(null);
            let arrMenuItems=[]
            props.module.categories.forEach(obj=>{
                arrMenuItems.push({label:obj.name, 'id': obj.id, "name": obj.name, "thumbnail": obj.thumbnail, 'serial_string': obj.serial_string})
            })
            setMenuItems(arrMenuItems)
            setGalleryItems(props.module.files);
          
            console.log('controller gallery', props.module.files)
            setStyleMenu(Globals.instance().getThemeStyle(['nav-menu-item'],props.theme))
            setStyleMenuSelected(Object.assign({},Globals.instance().getThemeStyle(['nav-menu-item'],props.theme),{color:'var(--primary-accent)', '--hover-color': 'var(--primary-accent)'}))
            // setCategories(props.module.mapboxes)
        } 
          // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [props.module.id])

    useEffect(() => {
        if (showJoystick) gsap.to(joyStickRef.current, {opacity: 1, duration: 0.3})
        else gsap.to(joyStickRef.current, {opacity: 0, duration: 0.3})
    }, [showJoystick])

    useEffect(() => {
        if (showVideo) {
            gsap.to(videoRef.current, {opacity: 1, duration: 0.3});
         
        }
        else {
            gsap.to(videoRef.current, {opacity: 0, duration: 0.3});
  
        }
    }, [showVideo])

    useEffect(() => {
       if (galleryItems && (galleryItems.length > 0)) {
            let result = galleryItems.some(item => item.fileType === "external" && item.external_src.includes('matterport'));
            // console.log('result', galleryItems, result);
            setIsMatterport(result);

            // if (galleryItems[0].fileType === 'video') {
            //     setFileType('video');
            //     setShowVideo(true);
            // }

       }
    }, [galleryItems])

    function onSerialCmd(objId, cmd) {
        let serials = [ ...activeSerials ];
        if (cmd === 'all') {
            serials = [];
        }
        let index = serials.findIndex(a => a === objId);
        if (index > -1) {

        } else {
            serials.push(objId)
            setActiveSerials(serials)
            ControllerServerComs.instance().sendUDPFromControllerToServer('serial-command', '1&' + cmd);
            console.log('onSerialCmd', '1&' + cmd)
        }
            
    }

    function onSerialCmdOff(objId, cmd) {
        console.log('received onSerialCmdOff', objId, cmd)
        let serials = [ ...activeSerials ];
            let index = serials.findIndex(a => a === objId);
            if (index > -1) {
                serials.splice(index, 1);
                setActiveSerials(serials);
                ControllerServerComs.instance().sendUDPFromControllerToServer('serial-command', '0&' + cmd);
                console.log('onSerialCmd', '0&' + cmd)
            } else {
         
            }
            
    }

    function onSerialCmdTap(objId, cmd, isOn) {

        if (btnReady) {
            console.log('tap')
            setBtnReady(false);
            if (!isOn) {
                onSerialCmd(objId, cmd)
               
            } else {
                onSerialCmdOff(objId, cmd)
            }
            setTimeout(() => {
                setBtnReady(true);
            }, 1000);
        }

       
    }

    function handleMenu(){
        if (menuOpen) {
            setMenuOpen(false)
        } else {
            setMenuOpen(true);
        }
    }

    function onThumbnailClick(id, type, file, method, catId) {
        console.log("onThumbnailClick", {id: id,  type: type, file: file, method: method, catId: catId});
        
        setSelectedId(id);
        setFileType(type);
        if (((type === 'vr')||(type === 'external'))&&((method === "image")||(method === "params"))) {
            (showJoystick) ? setShowJoystick(false) : setShowJoystick(true);
        }
        if ((type === "video")&&(method === "image")) {
            (showVideo) ? setShowVideo(false) : setShowVideo(true);
            setVidIsPlaying(true);
        }
        if ((type === "video")&&(method === "params")) {
            (showVideo) ? setShowVideo(false) : setShowVideo(true);
            setVidIsPlaying(false);
        }
        if (method !== "params") {
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-select-file', id);
        }
       
        
        let y = 0;
        let duration = 0;
        if (method === "image") {
            let elem = document.getElementById('galleryThumb_' + id)
            if (elem) {
                y = elem.offsetTop;
                duration = 0.3
            }
        }

        gsap.to(scrollContainer.current, {duration: duration, scrollTo: y}); 
        
        
    }

    const onClickMenuItem = (index) =>{
       
        setSelected(index)
        var filter = menuItems[Number(index)]
        var results = [];
        props.module.files.forEach(obj =>{ 
            obj.categories.includes(filter.id) && results.push(obj)
        });
        setGalleryItems(results);
        console.log("gallery click", filter, results, results.length)
       
        ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-select-category', filter.id);
        
        // if (results.length > 0) {
        //     ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-select-file', results[0].id);
        //     onThumbnailClick(results[0].id, results[0].fileType, results[0].file, 'link')
          
        // }

        setShowLanding(false);
        
    }

    const onClickLink = (index) =>{

        console.log('ControllerGallery: onClickLink', index)
        
        setSelected(index)
        var filter = menuItems[index]
        setActivePage(filter);
        var results = [];
        props.module.files.forEach(obj =>{ 
            obj.categories.includes(filter.id) && results.push(obj)
        });
        setGalleryItems(results);
        console.log("gallery click", filter, results)
        ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-select-category', filter.id);
        // if (filter.serial_string) {
        //     onSerialCmd(filter.id, filter.serial_string);
        // }
        if (results.length > 0) {
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-select-file', results[0].id);
            onThumbnailClick(results[0].id, results[0].fileType, results[0].file, 'link')
        }
        setShowLanding(false);

    }   

    const onClickClose = () => {
        console.log('close')
        props.setPopup(false);
        if ((fileType === 'vr')||(fileType === 'external')) {
            setShowJoystick(false);
        }
        if (fileType === 'vr') {
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "closeVr");
        }
        if (fileType === 'video') {
            setShowVideo(false);
            setVidIsPlaying(false);
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "vidpause");
        }

        if (backPageIndex) {
            onClickBack();
        }
        
       
    }

    // function onClickReturn(){
    //     if (backPageIndex) {
    //         let url = new URL(window.location.href)
    //         let search = "nav=closed&slide=" + backPageIndex
    //         props.history.push({pathname:url.pathname, search:'?'+ search})  
    //         // setShowModules(false) 
    //         console.log('onClickReturn', backPageIndex)
    //         props.onClickModule(-1, true, backPageIndex)
    //         // window.location.href = url.pathname + search
    //     }
    // }
   
   

    const onZoomOut = () => {
        // console.log('zoomOut') 
        ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "vrzoomout");
     }
 
     const onZoomIn = () => {
        //  console.log('zoomIn') 
         ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "vrzoomin");
      }

      const onPrevClick = () => {
        console.log('onPrevClick') 
        let current = galleryItems.findIndex(obj => obj.id === selectedId);
        if (current > -1) {
            let newIndex = current - 1;
            let prev = galleryItems[newIndex];
            if (prev) {
                setSelectedId(prev['id'])
                console.log('prev', prev)
                ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "prev");
            }   
            
        }
        
       
     }

     const onNextClick = () => {
       
        let current = galleryItems.findIndex(obj => obj.id === selectedId);
        console.log('onNextClick', current) 
        if (current > -1) {
            let newIndex = current + 1;
            let next = galleryItems[newIndex];
            
            if (next) {
                setSelectedId(next['id'])
                console.log('next', next)
                ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "next");
            }   
            
        }
        
     }

     const onKeyToggle = (command) => {
        console.log('onArrow', command) 
        ControllerServerComs.instance().sendUDPFromControllerToServer('key', command);
     }

     const onClickBack = () => {
        console.log('onClickBack', props.activePage) 
        props.setCurrentModule({typeName: 'controller-narrative'});
        // props.setActiveNarrativePageIndex(activePage);
        props.setAnimInCurrentModule(false);
        ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-close-module');

        console.log('props', props)
        // setSelected(null);
        // setShowLanding(true);
     }

    return (
        <React.Fragment>
           <div className={`${styles.linksContainer} ${styles.moduleLinksContainer} hide-scroller ${nstyles.galleryLinksContainer}`}>
                     
     
           {!props.showModules && <span className={nstyles.switchMenu} onClick={() => props.setShowModules(showModules => !showModules)}><CaretLeft /><h4>{props.module.title}</h4></span>}

                <div className={`${styles.controllerLinks} hide-scroller`} >
    
                        {!props.showModules && menuItems && (menuItems.length > 0) && menuItems.map((val, index) => (
                            <span 
                                key={`gallery_category_${index}`}
                                className={styles.controllerMenuLinkRow}
                            >
                            <span onClick={() => onClickLink(index)}>
                                <NarrativeMenuItem
                                model={val}
                                objId={val.id}
                                index={index}
                                onExpand={()=>{}}
                                className={classNames(
                                    nstyles.menuLink,
                                    styles.controllerMenuLink,
                                    activePage && activePage.id === val.id && 'linkOn',
                                    (activeSerials.indexOf(val.id) > -1) && styles.onSerial
                                )}
                                theme={props.theme}
                                activePage={activePage && activePage}
                                // style={selected===index ? styleMenuSelected : styleMenu}
                                pageIndex={selected && selected}
                                ></NarrativeMenuItem>
                              </span>   
                              {console.log('props.arduino', props.arduino, val.serial_string)}

                              {val.serial_string && props.arduino && <div className={`${styles.serialBtn} ${!(activeSerials.indexOf(val.id) > -1) && styles.serialBtnOff} ${(btnReady)?"":styles.serialBtnInUse}`} onClick={() => onSerialCmdTap(val.id, val.serial_string, (activeSerials.indexOf(val.id) > -1))}>
                                <Bulb className={styles.icon} />
                              </div>}
                        

                              </span>
                        ))}
                    </div>
            </div>                 

            {
            <section ref={scrollContainer} className={`${styles.moduleHolder}`}>
                {props.showBackBtn && <div className={`${styles.backBtnRow}`}>
                    <div className={`${styles.pillBtn}`} onClick={() => onClickBack()}>Back to Presentation</div>
                </div>}
                {(showLanding && menuItems && (menuItems.length > 1)) ? 
                    (<div className={`${styles.landingContainer}`}>
                            
                 
                        <div className={`${styles.itemsContainer}`}>
                            {menuItems.map((category, i) => (
                            <div 
                                className={`${styles.itemContainer}`}
                                key={'gallery_thumb_' + i}
                                onClick={() => onClickMenuItem(i)}
                            >
                                <img src={getContentPath(category.thumbnail)} alt=""></img>
                                <div className={`${styles.caption} ${styles.categoryCaption}`} style={(Globals.instance().getThemeStyle(['module-landing-primary'],props.theme))}>{category.name}</div>
                    
                            </div>
                            ))}
                        </div>
            
                    </div>)
                    : null}

                <div className={`${styles.galleryThumbnails} ${(props.showBackBtn)?styles.hasBackBtnRow:""}`}>
                    {galleryItems && galleryItems
                        .sort((a, b) => a.ordering - b.ordering)
                        .map((file, i) => (
                        <div 
                            onClick={() => onThumbnailClick(file.id, file.fileType, file.file, 'image')}
                            key={'gallery_file_' + i}
                            id={"galleryThumb_" + file.id}
                            className={`${styles.galleryThumbnail} ${(selectedId === file.id)?"linkOn":""}`}
                        >
                        <img src={getContentPath(file.thumbnail)} alt="" />
                        {(file.caption) ? 
                            <div 
                            className={`${styles.caption}`}
                       
                            >{file.caption}</div> 
                            : null}
                        </div>
                    ))}</div>
                
                    <div className={`${styles.moduleThumbsScrollContainerGradient}`}></div>
                    <div className={`${styles.buttons}`}>
                    <div className={`${styles.pillBtns}`}>
                    <div className={`${styles.pillBtn}`} onClick={() => onPrevClick()}><CaretLeft /></div>
                    <div className={`${styles.pillBtn} ${styles.pillBtnRight}`} onClick={() => onNextClick()}><CaretRight /></div>
                    </div>
                </div>
            </section>}
            {showJoystick &&  
                <section ref={joyStickRef} className={`${styles.galleryPopup}`}>
             
                    <ControllerJoystick 
                        fileType={`${(isMatterport)?"matterport":fileType}`}
                        twoFingerDrag={(fileType === "external")?true:false}
                    />
                    {(isMatterport) ?
                        <div className={`${styles.arrowRowsFlex}`}>
                            <div className={`${styles.arrowRows}`}>
                                <div className={`${styles.arrowRow} ${styles.arrowRow0}`}>
                                    <div className={`${styles.roundBtn}`} onClick={() => onKeyToggle('i')}><CaretUp /></div>
                                </div>
                                <div className={`${styles.arrowRow}  ${styles.arrowRow1}`}>
                                    <div className={`${styles.roundBtn}`} onClick={() => onKeyToggle('left')}><CaretLeft /></div>
                                </div>
                                <div className={`${styles.arrowRow}  ${styles.arrowRow2}`}>
                                    <div className={`${styles.roundBtn}`} onClick={() => onKeyToggle('right')}><CaretRight /></div>
                                </div>
                                <div className={`${styles.arrowRow}  ${styles.arrowRow3}`}>
                                    <div className={`${styles.roundBtn}`} onClick={() => onKeyToggle('k')}><CaretDown /></div>
                                </div>
                            </div>
                        </div>
                        
                    : null }
                   
                   {(fileType !== "external") ?
                   <div className={`${styles.buttons} ${styles.buttons70}`}>
                        <div className={`${styles.pillBtns}`}>
                        {(fileType === "vr")?(<React.Fragment><div className={`${styles.pillBtn}`} onClick={() => onZoomOut()}><ZoomOut /></div><div className={`${styles.pillBtn} ${styles.pillBtnRight}`} onClick={() => onZoomIn()}><ZoomIn /></div></React.Fragment>): null}
                        </div>
                    </div>
                        
                    : null }
                    
                    {((isMatterport)||(fileType === "external")||(fileType === "vr")) ?
                    <div className={`${styles.helpPanel}`}>  
                        <h2>Directions</h2>                  
                        {(isMatterport) ?
                        <div className={`${styles.helpRow}`}>
                            <h3>Matterport VR</h3>
                            <p>Use the trackpad to click hotspots around the room</p>
                            <img src={HelpTouch} alt="touch" /> 
                            <p>Use arrow controls to change viewpoint</p>
                        </div>
                        : null }
                        {(!isMatterport && (fileType === "external")) ?
                        <div className={`${styles.helpRow}`}>
                            <h3>Embedded VR</h3>
                            <p>Use 1 finger like a cursor to select on-screen buttons</p>
                            <img src={HelpTouch} alt="touch" />
                            <p>Use 2 fingers to pan around the room</p>
                            <img src={Help2Finger} alt="touch" />
                        </div>
                        : null }
                        {(fileType === "vr") ?
                        <div className={`${styles.helpRow}`}>
                        <p>Tap once to enter the VR</p>
                        <img src={HelpTouch} alt="touch" /> 
                        <p><b>A. Trackpad Option</b></p>
                        <p>Slide finger to pan around the space</p>
                        <img src={HelpSwipe} alt="touch" />
                        <p>Use buttons to zoom in/out</p>
                        <p><b>B. Arrow Option</b></p>
                        <p>Press and hold buttons to pan in desired direction</p>
                        <img src={HelpHold} alt="touch" />
                        </div>
                        : null }
                    </div>
                    : null }
                    
                    <span className={`${styles.joystickClose}`}>
                        <div className={`${mstyles.closeBtnContainer}`}>
                            <div className={`round-btn`} onClick={onClickClose} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                            <CloseButton></CloseButton>
                            </div>
                        </div>
                    </span>
                  

                </section>
            }
            {showVideo &&  
                <React.Fragment>
                    <ControllerVideoUI ref={videoRef} vidIsPlaying={vidIsPlaying} setVidIsPlaying={setVidIsPlaying} ></ControllerVideoUI>
                   
                    <span className={`${styles.joystickClose}`}>
                        <div className={`${mstyles.closeBtnContainer}`}>
                            <div className={`round-btn`} onClick={onClickClose} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                            <CloseButton></CloseButton>
                            </div>
                        </div>
                    </span>
                    </React.Fragment>
            }
        </React.Fragment>
    )

}

export default ControllerGallery
